import React from 'react';

import {CONTENT_TYPE} from 'src/utils/contentful';

import * as templates from './template-components';
import {
  ContentfulTemplateSharedProps,
  ContentfulTemplateComponentProps,
} from './types';

type AnyTemplateComponent = React.ComponentType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ContentfulTemplateComponentProps<any>
>;

type ContentfulTemplateDict = {
  [K in CONTENT_TYPE]?: AnyTemplateComponent;
};

const contentTypeTemplateMap: ContentfulTemplateDict = {
  // These two are using the same template. Deprecate one content model?
  topicSection: templates.GeneralTopicSection,
  inDepthTopicSection: templates.GeneralTopicSection,
  highlightTopicSection: templates.HighlightTopicSection,
  heroSection: templates.HeroSection,
  collectionLink: templates.CollectionLinkSection,
  featuredHeroProduct: templates.HeroProductSection,
  shortFeatureDescriptionGroup: templates.ShortFeatureDescriptionGroup,
  featuredProductSection: templates.FeaturedProductSection,
  featuredMembershipSection: templates.FeaturedMembershipSection,
  membershipFeaturesTable: templates.MembershipFeaturesTable,
  experimentContainer: templates.ExperimentContainer,
  textSection: templates.TextSection,
  featureBreakdown: templates.FeatureBreakdown,
  regionalContentContainer: templates.RegionalContentContainer,
  emailListSignup: templates.EmailListSignup,
  testimonialsContainer: templates.TestimonialsContainer,
  podcastLinksSection: templates.PodcastLinksSection,
  productsCollectionSection: templates.ProductsCollectionSection,
  threeColumnSection: templates.ThreeColumnSection,
  linkedContentSection: templates.LinkedContentSection,
  horizontalScrollSection: templates.HorizontalScrollSection,
};

const webPageTemplatesOverrides = {
  index: {
    ...contentTypeTemplateMap,
    heroSection: templates.HeroSection as AnyTemplateComponent,
  },
  'ad-feb': {
    ...contentTypeTemplateMap,
    heroSection: templates.HomeHero as AnyTemplateComponent,
  },
};

const isTemplateComponentContentType = (
  contentType: string
): contentType is keyof typeof contentTypeTemplateMap => {
  return !!contentType && contentType in contentTypeTemplateMap;
};

type PageSlug = 'index';

const getComponent = (contentType: string, pageSlug?: PageSlug) => {
  if (!isTemplateComponentContentType(contentType)) {
    return null;
  }

  if (pageSlug && webPageTemplatesOverrides[pageSlug]) {
    return webPageTemplatesOverrides[pageSlug][contentType];
  }

  return contentTypeTemplateMap[contentType];
};

export const ContentfulTemplatePicker: React.FC<ContentfulTemplateSharedProps> =
  ({entry, entryIndex, entries, pageEntry}) => {
    const contentType = entry.sys.contentType.sys.id;
    const pageSlug = pageEntry?.fields.slug as PageSlug | undefined;
    const Component = getComponent(contentType, pageSlug);

    if (!Component) {
      return null;
    }

    return (
      <Component
        entry={entry}
        entryIndex={entryIndex}
        entries={entries}
        contentfulTemplatePicker={ContentfulTemplatePicker}
        pageEntry={pageEntry}
      />
    );
  };
