import {ClientConfig} from '../../http';
import {format, subDays, getUnixTime} from 'date-fns';
import {
  IUser,
  PowerDurationCurves,
  IRunnerAttributesCollection,
  IUserWorkout,
  PredictionRequestParams,
  IRacePredictionParams,
  IRaceCourseDetail,
  IRaceCourses,
  IRaceCourseSummary,
  ICourseFileOpts,
  IDateRange,
  IRunBalanceHistory,
  CriticalPowerHistory,
  PowerDurationModels,
  IUserPlan,
  IUserCalendarResponse,
  ICourseDetails,
  IUserCalendarRequestParams,
  IUserCalendarClientParams,
  IWorkoutRescheduleParams,
  IWorkoutRequestResults,
  IWorkoutsDeleteParams,
  IWorkoutRequestParams,
  FeatureAccess,
  BillingPortalRequestParams,
  StripeSubscriptionOrder,
  RequestDepth,
  PowerDurationRequestOpts,
  UNSAFE_STRIPE,
  BuyingPlatform,
  IDevice,
  IAdminAutoCPResults,
  IOAuthUserIR,
  ISupplementalInfo,
} from '@stryd/models';
import {toURLParams, calendarTypeToCode} from '@stryd/util-lib';
import {convertToFormData} from '../utility';
import {ServiceHttpClients} from '../../types';
import {ShareBodyOptions, Shares} from '@stryd/models';

export const powerDurationDateRangeFormat = 'MM.dd.yyyy';

const getPdcDateRanges = (dateRanges: IDateRange[]) => {
  let ranges = '';
  dateRanges.forEach((dateRange, index) => {
    const start = format(dateRange.startDate, powerDurationDateRangeFormat);
    const end = format(dateRange.endDate, powerDurationDateRangeFormat);

    if (index === dateRanges.length - 1) {
      ranges += `${start}-${end}`;
    } else {
      ranges += `${start}-${end}_`;
    }
  });

  return ranges;
};

export const setupUserEndpoints = (clients: ServiceHttpClients) => {
  const urlPrefixBackend = `/b/api/v1/users`;
  const urlPrefixCanyon = `/canyon/users`;

  return {
    getById: (id: string, config?: ClientConfig) => {
      return clients.backend.get<IUser, string>(`${urlPrefixBackend}/${id}`, config);
    },

    editById: (id: string | number, data: Partial<IUser>, config?: ClientConfig) => {
      const fields = Object.keys(data).join(',');
      return clients.backend.put<IUser, string>(
        `${urlPrefixBackend}/${id}?fields=${fields}`,
        data,
        config
      );
    },

    editPasswordById: (
      id: string | number,
      data: {password: string; password_hash?: boolean},
      config?: ClientConfig
    ) => {
      return clients.backend.put<IUser, string>(
        `${urlPrefixBackend}/${id}?fields=${data.password_hash ? 'password_hash' : 'password'}`,
        {password: data.password},
        config
      );
    },

    getFatigueFitness: ({userId}: {userId: string}, configs?: ClientConfig) => {
      return clients.backend.get<IRunBalanceHistory, string>(
        `${urlPrefixBackend}/${userId}/fatiguefitness/all`,
        configs
      );
    },

    getCpHistory: (
      {userId, startDate, endDate}: {userId: string} & Partial<IDateRange>,
      configs?: ClientConfig
    ) => {
      const requestDateFormat = 'yyyy-MM-dd';
      return clients.backend.get<CriticalPowerHistory, string>(
        `${urlPrefixBackend}/${userId}/cp/history`,
        {
          params: {
            startDate: startDate ? format(startDate, requestDateFormat) : undefined,
            endDate: endDate ? format(endDate, requestDateFormat) : undefined,
          },
          ...configs,
        }
      );
    },

    deleteTrainingPlan: (id: string | number, configs?: ClientConfig) => {
      return clients.backend.delete<IUserPlan, string>(`${urlPrefixBackend}/plans/${id}`, configs);
    },

    updateTrainingPlan: (id: string | number, data: Partial<IUserPlan>, configs?: ClientConfig) => {
      return clients.backend.patch<IUserPlan, string>(
        `${urlPrefixBackend}/plans/${id}`,
        data,
        configs
      );
    },

    getPowerDurationCurves: (
      {userId, dateRanges, includeDetraining = true}: PowerDurationRequestOpts,
      config?: ClientConfig
    ) => {
      const ranges = getPdcDateRanges(dateRanges);

      const detraining = includeDetraining ? `&detraining=1` : `&detraining=0`;

      return clients.backend.get<PowerDurationCurves, string>(
        `${urlPrefixBackend}/${userId}/powerdurationcurve?datarange=${ranges}${detraining}`,
        config
      );
    },

    getPdcModel: ({userId}: {userId: string}, config?: ClientConfig) => {
      const today = new Date();
      const ranges = getPdcDateRanges([
        {
          startDate: subDays(today, 90),
          endDate: today,
        },
      ]);

      return clients.backend.get<PowerDurationModels, string>(
        `${urlPrefixBackend}/${userId}/powerdurationcurve?datarange=${ranges}&breakdown=1`,
        config
      );
    },

    getRunnerAttrs: (
      {
        userId,
        race = true,
        age = true,
        gender = true,
      }: {userId: string; race?: boolean; gender?: boolean; age?: boolean},
      config?: ClientConfig
    ) => {
      const raceParam = race ? 1 : 0;
      const genderParam = gender ? 1 : 0;
      const ageParam = age ? 1 : 0;

      return clients.backend.get<IRunnerAttributesCollection, string>(
        `${urlPrefixBackend}/${userId}/runner-attribute?race=${raceParam}&gender=${genderParam}&age=${ageParam}`,
        config
      );
    },

    getRacePrediction: (
      userId: string,
      params: PredictionRequestParams,
      configs?: ClientConfig
    ) => {
      if (params.target_power && params.target_time) {
        throw new Error(
          'You may not include both a target time and target power in the prediction request. Choose one. '
        );
      }

      const paramString = toURLParams(params);

      return clients.backend.get<IRacePredictionParams, string>(
        `${urlPrefixBackend}/${userId}/race/prediction?${paramString}`,
        configs
      );
    },

    getRaceCourses: (userId: string, configs?: ClientConfig) => {
      return clients.backend.get<IRaceCourses, string>(
        `${urlPrefixBackend}/${userId}/race/courses`,
        configs
      );
    },

    getRaceCourse: (
      {userId, id, depth}: {userId: string; id: number} & RequestDepth,
      configs?: ClientConfig
    ) => {
      const query = depth ? `?depth=${depth}` : ``;

      return clients.backend.get<ICourseDetails, string>(
        `${urlPrefixBackend}/${userId}/race/courses/${id}` + query,
        configs
      );
    },

    postRaceCourse: (userId: string, fileOpts: ICourseFileOpts, configs?: ClientConfig) => {
      const formData = convertToFormData(fileOpts);

      return clients.backend.post<IRaceCourseDetail, string | {message: string}>(
        `${urlPrefixBackend}/${userId}/race/courses`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          ...configs,
        }
      );
    },

    patchRaceCourse: (
      userId: string,
      courseId: number,
      fileOpts: Omit<ICourseFileOpts, 'file'>,
      configs?: ClientConfig
    ) => {
      return clients.backend.patch<IRaceCourseSummary, string>(
        `${urlPrefixBackend}/${userId}/race/courses/${courseId}`,
        fileOpts,
        configs
      );
    },

    deleteRaceCourse: (userId: string, courseId: number, configs?: ClientConfig) => {
      // response will have the id of the deleted course
      return clients.backend.delete<number, string>(
        `${urlPrefixBackend}/${userId}/race/courses/${courseId}`,
        configs
      );
    },

    getCalendar: (params: IUserCalendarClientParams, configs?: ClientConfig) => {
      const requestParams: IUserCalendarRequestParams = {
        from: params.from ? getUnixTime(params.from) : undefined,
        to: params.to ? getUnixTime(params.to) : undefined,
        updated_after: params.updated_after ? getUnixTime(params.updated_after) : undefined,
        limit: params.limit,
        fields: params.fields ? params.fields.map(calendarTypeToCode) : undefined,
        include_deleted: params.include_deleted,
      };

      const urlParams = toURLParams(requestParams);

      return clients.backend
        .get<IUserCalendarResponse, string>(
          `${urlPrefixBackend}/${params.userId}/calendar?${urlParams}`,
          configs
        )
        .then((res) => {
          if (res.ok) {
            if (res.data.supplementals) {
              res.data.supplementals.forEach((s) => (s.user_id = params.userId));
            }

            return res;
          }
          throw res;
        });
    },

    /**
     * If some edits fail, and some succeed, there is still a 200 status returned,
     * but with the errors noted in the response struct.
     *
     * If there is no errors, the `failure_ids` on the response will be null;
     */
    rescheduleWorkouts: (params: IWorkoutRescheduleParams, configs?: ClientConfig) => {
      return clients.backend.patch<IWorkoutRequestResults, string>(
        `${urlPrefixBackend}/workouts/reschedule`,
        params,
        configs
      );
    },

    /**
     * The `source` property of the workout must be included in the data
     * data will be `null`, even if the update succeeds
     */
    updateWorkoutById: (
      id: string,
      updates: Partial<IUserWorkout> & {source: string},
      configs?: ClientConfig
    ) => {
      return clients.backend.patch<null, string>(
        `${urlPrefixBackend}/workouts/${id}`,
        updates,
        configs
      );
    },

    /**
     * If some edits fail, and some succeed, there is still a 200 status returned,
     * but with the errors noted in the response struct.
     *
     * If there is no errors, the `failure_ids` on the response will be null;
     */
    deleteWorkouts: (params: IWorkoutsDeleteParams, configs?: ClientConfig) => {
      return clients.backend.delete<IWorkoutRequestResults, string>(
        `${urlPrefixBackend}/workouts`,
        {
          ...configs,
          data: params,
        }
      );
    },

    getWorkout: (params: IWorkoutRequestParams, configs?: ClientConfig) => {
      const {id, source, source_id} = params;
      return clients.backend.get<IUserWorkout, string>(
        `${urlPrefixBackend}/workouts/${id}?source=${source}&source_id=${source_id}`,
        configs
      );
    },

    updateSupplemental: (
      {
        id,
        planId,
        userId,
        data,
      }: {
        id: string;
        userId: string;
        planId: string;
        data: {
          day: number;
          completed: boolean;
        };
      },
      configs?: ClientConfig
    ) => {
      return clients.backend
        .patch<ISupplementalInfo, string>(
          `${urlPrefixBackend}/${userId}/plans/${planId}/supplementals/${id}`,
          data,
          configs
        )
        .then((res) => {
          if (res.ok) {
            res.data.user_id = userId;
            return res;
          }
          throw res;
        });
    },

    deleteSupplemental: (
      {id, planId, userId}: {id: string | number; userId: string | number; planId: string | number},
      configs?: ClientConfig
    ) => {
      return clients.backend.delete<IWorkoutRequestResults, string>(
        `${urlPrefixBackend}/${userId}/plans/${planId}/supplementals/${id}`,
        {
          ...configs,
        }
      );
    },

    getFeatureAccess: (userId: string, configs?: ClientConfig) => {
      return clients.backend.get<FeatureAccess, string>(
        `${urlPrefixBackend}/${userId}/feature-access`,
        configs
      );
    },

    cancelMembership: (userId: string, stripeId: string, configs?: ClientConfig) => {
      return clients.canyon.post<UNSAFE_STRIPE.StripeSubscriptionInfo, string>(
        `${urlPrefixCanyon}/${userId}/subscription/stripe/${stripeId}/cancel?format=purchase`,
        configs
      );
    },

    /** Returns a plain text body that contains a url to Stripe's billing management portal */
    redirectToBillingPortal: (params: BillingPortalRequestParams, configs?: ClientConfig) => {
      const {userId, stripeId} = params;
      return clients.canyon.get<string, string>(
        `${urlPrefixCanyon}/${userId}/subscription/stripe/${stripeId}/portal`,
        configs
      );
    },

    /** Returns 200 if a user has a membership, 204 if they do not */
    getMembership: (userId: string, configs?: ClientConfig) => {
      return clients.canyon.get<StripeSubscriptionOrder, string>(
        `${urlPrefixCanyon}/${userId}/membership`,
        configs
      );
    },

    getSubscriptionInfo: <Query extends {userId: string; id: string; platform: BuyingPlatform}>(
      params: Query,
      configs?: ClientConfig
    ) => {
      const {userId, platform, id} = params;
      return clients.canyon.get<UNSAFE_STRIPE.SubscriptionInfo, string>(
        `${urlPrefixCanyon}/${userId}/subscription/${platform}/${id}?format=purchase`,
        configs
      );
    },

    restoreMembership: (userId: string, stripeId: string, configs?: ClientConfig) => {
      return clients.canyon.post<UNSAFE_STRIPE.StripeSubscriptionInfo, string>(
        `${urlPrefixCanyon}/${userId}/subscription/stripe/${stripeId}/reactivate?format=purchase`,
        configs
      );
    },

    getShares: (userId: string, configs?: ClientConfig) => {
      return clients.backend.get<Shares, string>(`${urlPrefixBackend}/${userId}/shares`, configs);
    },

    /**
     * Requests a new share between this and the requested user if no share exists.
     * Confirms a share between this and the requested user if a request has already been made.
     */
    requestShare: (userId: string, data: ShareBodyOptions, configs?: ClientConfig) => {
      return clients.backend.post<Shares, string>(
        `${urlPrefixBackend}/${userId}/shares`,
        data,
        configs
      );
    },

    /**
     * Removes a share between this and the requested user
     */
    removeShare: (userId: string, data: ShareBodyOptions, configs?: ClientConfig) => {
      return clients.backend.post<Shares, string>(
        `${urlPrefixBackend}/${userId}/shares/remove`,
        data,
        configs
      );
    },

    sendPasswordResetLink: (email: string, config?: ClientConfig) => {
      return clients.backend.post<string, string>(`/b/reset/password?email=${email}`, config);
    },

    resetPassword: (params: {sessionId: string; password: string}, config?: ClientConfig) => {
      const {password, sessionId} = params;
      return clients.backend.post<string, string>(
        `/b/reset/password/${sessionId}`,
        {password},
        config
      );
    },

    recalculateCP: (userId: string, config?: ClientConfig) => {
      // server response with "Done!" if successful edit
      return clients.backend.put<string, string>(
        `${urlPrefixBackend}/${userId}/cp/recalculate`,
        config
      );
    },

    getDevices: (userID: string, configs?: ClientConfig) => {
      return clients.backend.get<IDevice[] | null, string>(
        `${urlPrefixBackend}/${userID}/devices/stryd`,
        configs
      );
    },

    getAutoCP: (params: {user_id: string} & Partial<IDateRange>, config?: ClientConfig) => {
      const {user_id, startDate, endDate} = params;

      return clients.backend.get<IAdminAutoCPResults, string>(
        `${urlPrefixBackend}/${user_id}/cp/auto`,
        {
          params: {
            from: startDate ? format(startDate, 'MM-dd-yyyy') : undefined,
            to: endDate ? format(endDate, 'MM-dd-yyyy') : undefined,
          },
          ...config,
        }
      );
    },

    getOAuthPermission: (params: {userId: string; provider: string}, config?: ClientConfig) => {
      return clients.backend.get<IOAuthUserIR[] | null, string>(
        `${urlPrefixBackend}/${params.userId}/oauth/permission?p=${params.provider}`,
        config
      );
    },

    getNewPodEligibility: (userId: string, configs?: ClientConfig) => {
      return clients.canyon.get<{promo_code: string; user_eligible: boolean}, string>(
        `${urlPrefixCanyon}/${userId}/promotions/pod-upgrade`,
        configs
      );
    },

    activateNewPodPromoCode: (userId: string, configs?: ClientConfig) => {
      return clients.canyon.post<{promo_code: string; user_eligible: boolean}, string>(
        `${urlPrefixCanyon}/${userId}/promotions/pod-upgrade`,
        configs
      );
    },
  };
};
